<template>
  <div class="compared">
    <div class="analysis-header rank-plate">
      <analyze-date-range
        @change="onChangeDateRange"
        :defaultDateRange="$store.state.otherParams"
        @changeSchool="onChangeSchool"
      ></analyze-date-range>
    </div>
    <div class="compared_choose">
      <div class="title">
        {{ $t("el.dataAnalysis.chooseContrastRange")
        }}{{ $t("el.symbol.colon") }}
      </div>
      <el-form ref="form" :model="form" label-width="auto" :rules="rules">
        <div class="flex">
          <el-form-item
            :label="$t('el.schoolResourceManage.grade') + $t('el.symbol.colon')"
            prop="grade"
          >
            <el-select
              v-model="form.grade"
              :placeholder="$t('el.common.pleaseSelect')"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="item in gradeOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            :label="
              $t('el.schoolResourceManage.subjects') + $t('el.symbol.colon')
            "
            prop="subject"
          >
            <el-select
              v-model="form.subject"
              :placeholder="$t('el.common.pleaseSelect')"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="item in subjectOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            :label="
              $t('el.schoolResourceManage.teacher') + $t('el.symbol.colon')
            "
            class="teacher"
            style="width: 300px"
          >
            <el-select
              v-model="form.teacher"
              multiple
              :placeholder="$t('el.common.pleaseSelect')"
              filterable
              @focus="focusSelect"
              style="width: 100%"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="item in teacherOptions"
                :key="item.userId"
                :label="item.staffName"
                :value="item.userId"
              >
                <span style="float: left">{{
                  item.staffName + "(" + item.mobile + ")"
                }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <el-form-item class="submit">
          <el-button @click="submitCompare">
            <span class="icon"></span>
            <span>{{ $t("el.dataAnalysis.toContrast") }}</span>
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <template v-if="showData">
      <!-- 备课时长对比 -->
      <div class="compared_box">
        <analysis-title before-icon="icon-tit-bar">
          {{ $t("el.dataAnalysis.preparingTimeContrast") }}
          <div slot="content">
            <p
              v-for="(item, index) in $t(
                'el.dataAnalysis.preparingTimeContrastDesc'
              )"
              :key="index"
            >
              {{ item }}
            </p>
          </div>
        </analysis-title>
        <bold-bar-rank :data="barData"></bold-bar-rank>
      </div>
      <!-- 授课时长及内容对比 -->
      <div class="compared_box">
        <analysis-title before-icon="icon-tit-bar">
          {{ $t("el.dataAnalysis.TeachingTimeContrast") }}
          <div slot="content">
            <p
              v-for="(item, index) in $t(
                'el.dataAnalysis.TeachingTimeContrastDesc'
              )"
              :key="index"
            >
              {{ item }}
            </p>
          </div>
        </analysis-title>
        <bold-bar-rank :data="barDataTeach"></bold-bar-rank>
      </div>
      <!-- 课程授课使用率对比 -->
      <div class="compared_box">
        <analysis-title before-icon="icon-tit-cycle">
          {{ $t("el.dataAnalysis.curriculumTeachingUtilization") }}
          <div slot="content">
            <p
              v-for="(item, index) in $t(
                'el.dataAnalysis.curriculumTeachingUtilizationDesc'
              )"
              :key="index"
            >
              {{ item }}
            </p>
          </div>
        </analysis-title>
        <div>
          <div
            class="compared_select"
            :class="{ compared_select_pb: chooseCourse == '' }"
          >
            <label for=""
              >{{ $t("el.dataAnalysis.selectCoursesToAnalysis")
              }}{{ $t("el.symbol.colon") }}</label
            >
            <el-select
              v-model="chooseCourse"
              :placeholder="$t('el.common.pleaseSelect')"
              :popper-append-to-body="false"
              @change="chooseCourseChange"
              filterable
            >
              <el-option
                v-for="item in courseOptions"
                :key="item.id"
                :label="item.courseName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <template v-if="chooseCourse">
            <template v-if="courseTeachArr.length">
              <div class="compared_unit">
                {{ $t("el.dataAnalysis.unit") }}{{ $t("el.symbol.colon") }}%
              </div>
              <div class="compared_ball flex">
                <circle-chart
                  v-for="(item, index) in courseTeachArr"
                  :key="item.userId + '' + index"
                  class="compared_ball_item"
                  :color="
                    index % 3 === 0
                      ? colorArr[0]
                      : index % 3 === 1
                      ? colorArr[1]
                      : colorArr[2]
                  "
                  :name="item.staffName"
                  :num="Math.round(item.courseUseRate * 1000) / 10"
                ></circle-chart>
              </div>
            </template>
            <not-data v-else></not-data>
          </template>
        </div>
      </div>
    </template>
    <div class="no_data" v-else>
      ↑ {{ $t("el.dataAnalysis.selectContrastRange") }}
    </div>
  </div>
</template>

<script>
import notDataVue from "@/components/teaching-components-chart/not-data/index.vue";
import analyzeDateRangeVue from "@/components/teaching-components-chart/analyze-date-range/index.vue";
import analysisTitleVue from "@/components/teaching-components-chart/analysis-title/index.vue";
import circleChartVue from "@/components/teaching-components-chart/circle-chart/index.vue";
import boldBarRankVue from "@/components/teaching-components-chart/bold-bar-rank/index.vue";
import {
  getTeacherList,
  teacherCompare,
  queryCourse,
  useRate,
} from "@/api/manage/compared-analyze";
import { genQuickDateRange } from "@/utils";
import gradeAndSubject from "@/mixin/gradeAndSubject.js";

export default {
  name: "compared",
  // 注册组件
  components: {
    [analyzeDateRangeVue.name]: analyzeDateRangeVue, // 日期范围组件
    [analysisTitleVue.name]: analysisTitleVue, // 图表标题组件
    [boldBarRankVue.name]: boldBarRankVue, // 条形图
    [notDataVue.name]: notDataVue, // 无数据
    [circleChartVue.name]: circleChartVue, // 水球图
  },
  mixins: [gradeAndSubject],
  data() {
    return {
      form: {
        grade: "",
        subject: "",
        teacher: [],
      },
      rules: {
        grade: [
          {
            required: true,
            message: this.$t("el.dataAnalysis.selectGrade"),
            trigger: "change",
          },
        ],
        subject: [
          {
            required: true,
            message: this.$t("el.dataAnalysis.selectSubject"),
            trigger: "change",
          },
        ],
      },
      showData: false, // 默认不展示数据
      chooseCourse: "",
      gradeOptions: [], // 年级选项
      subjectOptions: [], // 科目选项
      teacherOptions: [], // 老师选项
      teacherOptionsInit: [], // 老师选项初始化
      courseOptions: [], // 水球图课程
      /**
       * 其他参数
       */
      otherParams: {
        startDate: "",
        endDate: "",
      },
      colorArr: [
        "rgba(73, 124, 240, 0.7)",
        "rgba(84, 195, 54, 0.7)",
        "rgba(151, 86, 255, 0.7)",
      ],
      prepareXAxisData: [], // 备课对比X
      prepareYAxisData: [], // 备课对比Y
      teachXAxisData: [], // 授课对比X
      teachYAxisData: [], // 授课对比Y
      courseTeachArr: [],
    };
  },
  computed: {
    /**
     * 柱状图数据 -备课
     */
    barData() {
      return {
        offset: [25, 0],
        position: "insideRight",
        color: "#595959",
        itemStyle: ["rgba(73, 124, 240, 0.7)"],
        legend: [this.$t("el.dataAnalysis.preparationTime")],
        xAxisData: this.prepareXAxisData,
        yAxisData: this.prepareYAxisData,
      };
    },
    /**
     * 柱状图数据 -授课
     */
    barDataTeach() {
      return {
        offset: [25, 0],
        position: "insideRight",
        color: "#595959",
        itemStyle: ["rgba(73, 124, 240, 0.7)"],
        legend: [this.$t("el.dataAnalysis.teachingDailyTime")],
        xAxisData: this.teachXAxisData,
        yAxisData: this.teachYAxisData,
      };
    },
  },
  mounted() {
    // 初始化默认值
    this.initData();
  },
  methods: {
    /**
     * 初始化默认值
     */
    initData() {
      if (this.$store.state.otherParams) {
        if (isNaN(this.$store.state.otherParams)) {
          this.onChangeDateRange(this.$store.state.otherParams);
        } else {
          this.onChangeDateRange(
            genQuickDateRange(this.$store.state.otherParams)
          );
        }
      } else {
        this.onChangeDateRange(genQuickDateRange(7));
      }
      console.log("optionInit");
      this.optionInit();
    },
    /**
     * select 焦点
     */
    focusSelect() {
      console.log("focusSelect");
      this.$refs.form.validate((valid) => {
        if (!valid) return false;
        this.teacherOptions = this.teacherOptionsInit;
      });
    },
    /**
     * 时间范围改变
     */
    onChangeDateRange(data) {
      // 校验数据
      if (!data && data.length > 2) return console.warn("参数错误");
      this.otherParams = {
        startDate: this.$dateFormat(data[0], "yyyy-MM-dd"),
        endDate: this.$dateFormat(data[1], "yyyy-MM-dd"),
      };
      if (this.$store.state.schoolCode) {
        this.otherParams = {
          ...this.otherParams,
          tenantId: this.$store.state.schoolCode, // 学校编码
        };
      }
      if (this.form.grade && this.form.subject) {
        this.submitCompare();
      }
    },
    /**
     * 学校 选择
     */
    onChangeSchool(schoolCode) {
      // 初始化默认值
      this.initData();
      this.$emit("changeSchool", schoolCode);
    },
    /**
     * 选择需分析课程
     */
    chooseCourseChange() {
      let params = {
        gradeId: this.form.grade,
        subjectId: this.form.subject,
        courseId: this.chooseCourse,
        staffCodeListStr: this.form.teacher.join(","),
      };
      useRate(Object.assign(params, this.otherParams)).then((res) => {
        this.courseTeachArr = res.data;
      });
    },
    /**
     * 对比分析按钮
     */
    submitCompare() {
      this.$refs.form.validate((valid) => {
        if (!valid) return false;

        this.showData = true;
        let params = {
          gradeId: this.form.grade,
          subjectId: this.form.subject,
        };
        this.$store.commit("setFormCompare", this.form);
        teacherCompare(
          Object.assign(
            { useType: 0, staffCodeListStr: this.form.teacher.join(",") },
            params,
            this.otherParams
          )
        ).then((res) => {
          this.prepareXAxisData = [];
          this.prepareYAxisData = [];
          this.prepareYAxisData[0] = [];
          let data = res.data;
          for (let i = data.length - 1; i >= 0; i--) {
            this.prepareXAxisData.push(data[i].staffName);
            this.prepareYAxisData[0].push(data[i].prepareTime);
          }
        });
        teacherCompare(
          Object.assign(
            { useType: 1, staffCodeListStr: this.form.teacher.join(",") },
            params,
            this.otherParams
          )
        ).then((res) => {
          let data = res.data;
          this.teachXAxisData = [];
          this.teachYAxisData = [];
          let standardYAxisData = [];
          for (let i = data.length - 1; i >= 0; i--) {
            this.teachXAxisData.push(data[i].staffName);
            standardYAxisData.push(data[i].teachTime);
          }
          this.teachYAxisData = [standardYAxisData];
          console.log("this.teachYAxisData", this.teachYAxisData);
        });
        queryCourse(params).then((res) => {
          this.courseOptions = res.data;
        });
        this.chooseCourse = "";
      });
    },
    /**
     * 选项初始化
     */
    async optionInit() {
      /**
       * 老师选项
       */
      getTeacherList({ tenantId: this.$store.state.schoolCode }).then((res) => {
        this.teacherOptionsInit = res.data;
        // if (this.$store.state.formCompare.teacher) {
        this.teacherOptions = res.data;
        // this.form.teacher = this.$store.state.formCompare.teacher;
        this.form.teacher = [];
        // }
        if (this.form.grade && this.form.subject) {
          this.submitCompare();
        }
      });
      await this.handleGetGradeAndSubject(); // mixin获取年级与科目
      if (this.$store.state.formCompare.grade) {
        this.form.grade = this.$store.state.formCompare.grade;
      }
      if (this.$store.state.formCompare.subject) {
        this.form.subject = this.$store.state.formCompare.subject;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.analysis-header {
  background: #fff;
  padding: 10px 0;
  border-radius: 4px 4px 0px 0px;
}
.flex {
  flex-wrap: wrap;
}
.compared {
  width: 100%;
  box-sizing: border-box;
  background-color: #f6f8fc;
  &_choose {
    background-color: #fff;
    padding-top: 6px;
    padding-left: 20px;
    padding-bottom: 23px;
    padding-right: 20px;
    border-radius: 0px 0px 4px 4px;
    .title {
      font-size: 14px;
      font-weight: bold;
      color: #464646;
      line-height: 19px;
      padding-bottom: 18px;
    }
    /deep/ .el-form-item {
      margin-right: 30px;
    }
    /deep/ .el-form-item__label {
      text-align: left;
      font-size: 14px;
      color: #464646;
    }
    // /deep/ .el-form-item.teacher .el-input__inner {
    //   width: 360px;
    // }
    /deep/ .el-select__tags {
      flex-wrap: nowrap;
      overflow-x: auto;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
    /deep/ .el-tag.el-tag--info {
      background: rgba(120, 145, 168, 0.18);
      border-radius: 26px;
      border-color: rgba(120, 145, 168, 0.18);
      color: #282828;
    }
    /deep/ .el-tag .el-icon-close {
      background: none;
      right: -3px;
    }
    /deep/ .el-tag .el-icon-close::before {
      color: rgba(111, 138, 163, 1);
      font-size: 18px;
      font-weight: 600;
    }
    /deep/ .el-input__inner {
      // width: 160px;
      height: 32px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      font-size: 14px;
    }
    /deep/ .submit .el-form-item__content {
      margin-left: 0 !important;
      margin-top: -2px;
    }
    /deep/ .el-button {
      width: 106px;
      padding: 8px 0;
      background: linear-gradient(180deg, @themeBlue 0%, #4072ee 100%);
      border-radius: 4px;
      font-size: 12px;
      color: #f9f9f9;
      border: 0;
      span {
        vertical-align: middle;
        line-height: 14px;
        & + span {
          padding-left: 4px;
        }
      }
      .icon {
        width: 14px;
        height: 14px;
        vertical-align: middle;
        .gen-icon("icon_contrast@2x");
      }
    }
  }
  &_select {
    font-size: 14px;
    color: #464646;
    margin-top: 38px;
    padding-left: 52px;
    /deep/ .el-input__inner {
      width: 260px;
      height: 32px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      font-size: 14px;
    }
    /deep/ .el-input__icon {
      line-height: 32px;
    }
    &_pb {
      padding-bottom: 76px;
    }
  }
  &_box {
    background-color: #fff;
    margin-top: 10px;
    padding: 22px 26px 20px 20px;
  }
  &_ball {
    padding-left: 99px;
    padding-right: 99px;
    padding-top: 26px;
    padding-bottom: 35px;
    flex-wrap: wrap;
    &_item {
      margin-right: 110px;
    }
  }
  &_unit {
    font-size: 12px;
    font-weight: 400;
    color: #8c8c8c;
    line-height: 17px;
    padding-left: 25px;
    padding-top: 21px;
  }
  .no_data {
    font-size: 22px;
    color: #949494;
    line-height: 29px;
    letter-spacing: 1px;
    text-align: center;
    padding-top: 88px;
  }
  .analysis-title {
    margin-left: -20px;
  }
}
/deep/
  .el-form-item.is-required:not(.is-no-asterisk)
  .el-form-item__label-wrap
  > .el-form-item__label:before,
.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  content: "";
}
</style>
