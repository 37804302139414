<template>
  <div class="bold-bar-rank">
    <div
      class="echarts-warpper"
      :class="{ 'echarts-no-data': !data.xAxisData.length }"
    >
      <e-charts
        v-if="data.xAxisData.length"
        ref="echarts"
        :options="options"
      ></e-charts>
      <not-data v-else></not-data>
    </div>
  </div>
</template>

<script>
import "echarts/lib/chart/bar";
import "echarts/lib/component/markLine";
import "echarts/lib/component/legend";
import "echarts/lib/component/tooltip";

import notDataVue from "@/components/teaching-components-chart/not-data/index.vue";

export default {
  name: "bold-bar-rank",
  components: {
    [notDataVue.name]: notDataVue, // 无数据
  },
  props: {
    /**
     * 数据
     */
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {};
  },
  computed: {
    // 图表配置
    options() {
      console.log("this.data===", this.data);
      return {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        legend: {
          data: this.data.legend,
          selectedMode: false, // 不可选择
          itemWidth: 8, // 元素方块宽度
          itemHeight: 8, // 元素方块高度
          bottom: 11, // 设置底部
          textStyle: {
            // 文笔样式
            color: "#8C8C8C",
            fontSize: 12,
            lineHeight: 17,
          },
        },
        grid: {
          left: 49,
          right: 63,
          bottom: 58,
          top: 59,
          containLabel: true,
        },
        xAxis: {
          type: "value",
          axisLine: {
            show: false,
          },
          splitLine: {
            // 分割线
            color: "rgba(256, 256, 266, 0.15)", // 刻度线颜色
          },
          axisTick: {
            // 刻度线配置
            show: false, // 隐藏刻度
          },
          axisLabel: {
            // 元素文字颜色
            fontSize: 12,
            color: "rgba(0, 0, 0, 0.45)",
          },
        },
        yAxis: {
          name:
            this.$t("el.dataAnalysis.unit") +
            this.$t("el.symbol.colon") +
            this.$t("el.MyHome.minutes"),
          nameTextStyle: {
            color: "#8C8C8C",
            fontWeight: 400,
          },
          type: "category",
          data: this.xAxisData,
          axisLine: {
            // 水平线配置
            show: false, // 隐藏
          },
          axisTick: {
            // 刻度线配置
            show: false, // 隐藏刻度
          },
          axisLabel: {
            // 元素文字颜色
            fontSize: 12,
            color: "rgba(0, 0, 0, 0.45)",
          },
        },
        series: this.seriesData,
      };
    },
    /**
     * x轴数据
     */
    xAxisData() {
      return (
        (this.data &&
          Array.isArray(this.data.xAxisData) &&
          this.data.xAxisData) ||
        []
      );
    },
    /**
     * 柱状图数据
     */
    seriesData() {
      let seriesDataArr = [];
      let {
        offset,
        position,
        color,
        itemStyle,
        legend,
        xAxisData, // y轴老师数据列表
        yAxisData, // 柱状图数据
      } = this.data;
      console.log("yAxisData===", yAxisData);
      yAxisData.forEach((item, index) => {
        seriesDataArr.push({
          name: legend[index],
          type: "bar",
          stack: this.$t("el.dataAnalysis.TheTotal"),
          label: {
            // 文字配置
            show: true,
            offset: offset, // 位置偏移
            color: color,
            position: position,
          },
          barWidth: 26,
          data: item,
          itemStyle: {
            // 柱状样式
            color: itemStyle[index],
          },
        });
      });
      return seriesDataArr;
    },
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.echarts-warpper {
  max-width: 1134px;
  margin: 0 auto;
  height: 565px;
  overflow-y: auto;
}
.echarts-no-data {
  height: auto;
}
.echarts {
  width: 100%;
  margin: 0 auto;
  min-height: 565px;
}
</style>
