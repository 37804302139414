<template>
  <div class="course-teach">
    <div class="echarts-warpper">
      <e-charts ref="echarts" :options="options"></e-charts>
      <div class="echarts-name">{{ name }}</div>
    </div>
  </div>
</template>

<script>
import "echarts";
// import "echarts/lib/chart/bar";
import "echarts-liquidfill";

export default {
  name: "circle-chart",
  props: {
    color: {
      type: String,
      default: "#7c9ff2",
    },
    name: {
      type: String,
      default: "",
    },
    num: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  computed: {
    getvalue() {
      return [this.num];
    },
    options() {
      return {
        angleAxis: {
          max: 100,
          startAngle: this.startAngle,
          // 隐藏刻度线
          show: false,
        },
        radiusAxis: {
          type: "category",
          show: true,
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        polar: {
          center: ["50%", "50%"],
          radius: "180%", // 图形大小
        },
        title: {
          text: this.getvalue + "%",
          textStyle: {
            fontSize: 18,
            fontWeight: "bold",
            color: this.color,
          },
          left: "center",
          top: "middle",
        },
        series: [
          {
            type: "bar",
            data: this.getvalue,
            showBackground: true,
            backgroundStyle: {
              color: "#F0F3F8",
            },
            coordinateSystem: "polar",
            roundCap: true,
            barWidth: 10,
            itemStyle: {
              normal: {
                color: this.color,
                borderColor: "#fff",
                borderWidth: 2,
              },
            },
          },
          {
            type: "liquidFill",
            data: [this.getvalue[0] * 0.01],
            radius: "90%",
            label: {
              normal: {
                formatter: "",
                textStyle: {
                  fontSize: 18,
                },
              },
            },
            backgroundStyle: {
              color: "#fff", // 水球未到的背景颜色
            },
            color: ["rgba(122,156,237,0.1)"],
            opacity: 0.2,
            outline: {
              show: false,
            },
          },
        ],
      };
    },
    startAngle() {
      return this.getvalue[0] * 1.8 - 90;
    },
  },
};
</script>

<style lang="less" scoped>
.echarts-warpper {
  max-width: 1134px;
  margin: 0 auto;
  /deep/ .echarts {
    width: 130px;
    height: 130px;
  }
  .echarts-name {
    text-align: center;
    font-size: 14px;
    color: #282828;
    line-height: 19px;
    margin-top: 12px;
    margin-bottom: 17px;
  }
}
</style>
